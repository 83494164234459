import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const SiennicaCmentarzParafialny = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Zbiory Towarzystwa Przyjaciół Mińska Mazowieckiego" />
      <ReturnToPrevPage to={'/gminy/minsk-mazowiecki'}>
        Materiały dotyczące miasta Mińsk Mazowiecki oraz otaczającej go gminy
      </ReturnToPrevPage>
      <Header1>Zbiory Towarzystwa Przyjaciół Mińska Mazowieckiego</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          Towarzystwo Przyjaciół Mińska Mazowieckiego jest jednym z najstarszych
          stowarzyszeń funkcjonujących na terenie powiatu mińskiego. Utworzone
          zostało 6 maja 1962 r., zarejestrowane 10 czerwca 1962 w Warszawie.
          Inicjatorem założenia Towarzystwa było grono przedstawicieli
          miejscowej inteligencji, głównie nauczycieli i urzędników. Pierwszym
          prezesem TPMM został Stanisław Ciąćka.
        </p>
        <p>
          Za najważniejszy powód powstania Towarzystwa należy uznać konieczność
          ożywienia zainteresowania mińszczan przeszłością miasta i powiatu
          mińskiego. W związku z tym skrupulatnie realizowano cele zapisane w
          statucie TPMM, koncentrujące się na działalności odczytowej,
          wystawienniczej, fotoreporterskiej, archiwalno – bibliotecznej i
          wydawniczej. Organizowano również sesje popularnonaukowe oraz
          upamiętniano miejsca walk i męczeństwa narodu polskiego znajdujące się
          na terenie miasta i powiatu mińskiego. Do najważniejszych osiągnięć
          Towarzystwa w latach 1962 – 1989 należy zaliczyć zorganizowanie sesji
          popularnonaukowych z okazji setnej rocznicy wybuchu powstania
          styczniowego i 550 rocznicy założenia miasta Mińsk Mazowiecki. Efektem
          drugiego z wymienionych wydarzeń było wydanie jedynej do tej pory
          monografii prezentującej dzieje miasta nad Srebrną. Przełom lat
          osiemdziesiątych i dziewięćdziesiątych ubiegłego wieku przyniósł
          kryzys w funkcjonowaniu TPMM, który doprowadził do ustania
          działalności stowarzyszenia. Z tego „dziejowego zakrętu” Towarzystwo
          wyprowadzili burmistrz miasta Mińska Mazowieckiego Zbigniew Grzesiak i
          nowy prezes TPMM Janusz Kuligowski (od 1991 r.).
        </p>
        <p>
          Ich zaangażowanie wsparte pomocą niesioną ze strony postaci tak
          istotnych dla Towarzystwa jak m.in. Leontyna Sprzątczak, Marek
          Nowicki, Mariusz Dzienio, Franciszek Zwierzyński i Stanisław
          Ciszkowski przyniosło realizację zamierzonych celów. Do nowych
          osiągnięć stowarzyszenia, mających miejsce w ostatnim dziesięcioleciu
          XX i w dwóch dekadach XXI w. należy zaliczyć choćby bogatą działalność
          wydawniczą na czele z „Rocznikiem Mińskomazowieckim”, organizację
          licznych wystaw, przygotowywanie i przeprowadzanie objazdów
          historyczno – turystycznych, ratowanie od zniszczenia zabytkowych
          nagrobków znajdujących się na mińskim cmentarzu parafialnym i
          uczestniczenie przedstawicieli TPMM (w tym pocztu sztandarowego) w
          patriotycznych uroczystościach miejskich i powiatowych. Dodatkowym
          powodem do dumy stało się posiadanie własnej siedziby, w której
          znalazło się miejsce nie tylko na bogaty księgozbiór, ale i na
          archiwum skupiające materiały cenne z punktu widzenia badań
          prowadzonych nad historią miasta. W trakcie swojej działalności
          Towarzystwo współpracowało z licznymi podmiotami miejskimi i
          powiatowymi, jednostkami samorządowymi, szkołami, organizacjami
          pozarządowymi i z wieloma indywidualnymi miłośnikami lokalnej
          historii. Nowoczesnym przejawem funkcjonowania stowarzyszenia stało
          się prowadzenie przez nie własnej strony internetowej (
          <a href="www.tpmm.pl" target="_blank" rel="nofollow">
            www.tpmm.pl
          </a>
          ), profilu facebook’owego i skrzynki poczty elektronicznej
          (tpmmaz@gmail.com), dzięki czemu nawiązano różnorodne kontakty w skali
          międzynarodowej i ogólnopolskiej.
        </p>
        <p>
          W uznaniu zasług, które TPMM wniosło w życie miasta Mińska
          Mazowieckiego i powiatu mińskiego Towarzystwo zostało uhonorowane
          prestiżowym tytułem „Zasłużony dla miasta Mińsk Mazowiecki” (2011 r.)
          i nagrodą powiatu mińskiego „Laura 2011” (2012 r.). Jak już wspomniano
          ważną rolę w działalności stowarzyszenia odgrywa pozyskiwanie,
          opracowywanie i eksponowanie pamiątek dokumentujących dzieje miasta.
          Wśród nich wyróżnia się bogaty zbiór fotografii pokazujących miasto na
          przestrzeni ponad stu lat. Wraz z unikatowymi pocztówkami,
          znajdującymi się w zasobie organizacji pozarządowej, stały się one
          bazą dla niejednego wydawnictwa opublikowanego przez TPMM. Warto
          zauważyć, że około ćwierć tysiąca z nich zostało zaprezentowanych w
          albumie zatytułowanym „Mińsk Mazowiecki i okolice na dawnej
          fotografii”. Ta istotna część eksponatów będących w posiadaniu
          stowarzyszenia powstała dzięki działalności różnych, często nieznanych
          nam, przedwojennych miłośników uwieczniania widoków miasta na
          zdjęciach. W gronie tych, których udało się zidentyfikować można
          wymienić m.in. Mariana Benkę, Władysława Kopytowskiego, Jana Rysia
          oraz Stefana Sażyńskiego.
        </p>
        <p>
          Grupę powojennych fotografów reprezentowanych poprzez prace przekazane
          do zbiorów Towarzystwa tworzą natomiast m.in. Ryszard Ablewski,
          Stanisław Brewczyński, Stanisław Ciszkowski, Zygmunt Zieliński.
          Istotną część zbiorów tworzą również spuścizny po Marianie Benko i
          Janie Himilsbachu. Na pierwszą z nich, będącą obecnie w depozycie
          Muzeum Ziemi Mińskiej, składają się np. publikacje, w których ten
          wybitny mińszczanin umieszczał swoje artykuły, zdjęcia, rysunki
          przedstawiające obiekty zlokalizowane obiekty w powiecie mińskim oraz
          kilkusetstronicowe wspomnienia. Zespół pamiątek po jednym z
          kontrowersyjnych polskich aktorów i literatów obejmuje z kolei
          przykłady jego prozatorskiej twórczości, fotografie, świadectwa i
          pisma urzędowe, legitymacje, obrazy, meble oraz przedmioty codziennego
          użytku. Warto dodać, że Towarzystwo dysponuje ponadto rzeczami
          dotyczącymi rodziny Chróścielewskich. Wśród nich można wymienić
          pamiątkowe albumy i korespondencję rodzinną. Na różnorodne zbiory
          znajdujące się w posiadaniu TPMM składa się ponadto kilka sztandarów,
          kolekcja aparatów fotograficznych, galowy mundur Straży Miejskiej w
          Mińsku Mazowieckim, monety i banknoty oraz materiały przybliżające
          działalność nieistniejących już zakładów pracy. Z zachowaniem
          ostrożności przechowywane są w siedzibie Towarzystwa obrazy i grafiki
          twórców związanych z ziemią mińską, proporczyki i archiwalne gazety.
          Zbiory posiadane przez organizację pozarządową są reprezentowane także
          przez księgozbiór składający się z ponad półtora tysiąca woluminów
          oraz okolicznościowe medale, statuetki, puchary, dyplomy, tarcze
          szkolne, mapy i przypinki. Szczegóły dotyczące historii i
          teraźniejszego funkcjonowania Towarzystwa Przyjaciół Mińska
        </p>
        <p>
          Mazowieckiego można znaleźć na stronie internetowej stowarzyszenia (
          <a href="www.tpmm.pl" target="_blank" rel="nofollow">
            www.tpmm.pl
          </a>
          ) oraz na jego profilu facebook’owym. Dodajmy, że we wtorki w
          godzinach 17:00 – 19:00 w obecnej siedzibie TPMM, znajdującej się przy
          ulicy Marszałka Józefa Piłsudskiego 27, pełnione są dyżury członków
          zarządu stowarzyszenia. Wtedy to można m.in. można obejrzeć przykłady
          ze zbiorów jednej z najstarszej organizacji pozarządowej
          funkcjonujących w mieście nad Srebrną.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/minsk-mazowiecki/zbiorytpmm/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(
      filter: { relativePath: { regex: "/minsk-mazowiecki/zbiorytpmm/" } }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default SiennicaCmentarzParafialny
